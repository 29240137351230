import type { Dayjs } from 'dayjs'

export function calculateCalendarYear(
  currentStartDate: Dayjs,
  leaseEndDate: Dayjs,
) {
  return currentStartDate.endOf('year').isBefore(leaseEndDate)
    ? currentStartDate.endOf('year')
    : leaseEndDate
}
