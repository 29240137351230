import type { Dayjs } from 'dayjs'
import { YearType } from '@manager'

export function calculateNextStartDate(
  yearType: YearType,
  currentStartDate: Dayjs,
  currentEndDate: Dayjs,
) {
  return yearType === YearType.CALENDAR_YEAR
    ? currentStartDate.add(1, 'year').startOf('year')
    : currentEndDate.add(1, 'day')
}
