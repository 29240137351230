import { YearType } from '@manager'
import type { Dayjs } from 'dayjs'
import {
  calculateCalendarYear,
  calculateFinancialYear,
  calculateLeaseYear,
} from './'

export function calculateEndDateByYearType(
  yearType: YearType,
  currentStartDate: Dayjs,
  leaseEndDate: Dayjs,
  financialYearDate?: string,
) {
  switch (yearType) {
    case YearType.LEASE_YEAR: {
      return calculateLeaseYear(currentStartDate, leaseEndDate)
    }
    case YearType.CALENDAR_YEAR: {
      return calculateCalendarYear(currentStartDate, leaseEndDate)
    }
    case YearType.FINANCIAL_YEAR_END: {
      return calculateFinancialYear(
        currentStartDate,
        leaseEndDate,
        financialYearDate,
      )
    }
    // Add more cases for additional types if needed
    default: {
      return calculateFinancialYear(
        currentStartDate,
        leaseEndDate,
        financialYearDate,
      )
    }
  }
}
